import {
    actualizar_campos_exigidos_cuenta, actualizarFilaAjuste, actualizarFilaCompra,
    consultarPrecioUnitario, consultarPrecioUnitarioParaAjuste, consultarFacturaNotaDebito
} from './movimientos';

function ajustarAutocomplete(evento) {
    $(document).on("focus", ".akenaton-autocomplete", generarAutoComplete);
}

function generarAutoComplete(evento) {
    let element = $(evento.currentTarget);
    if (element.parents(".easy-autocomplete").length == 0) {
        let idElement = element.data("id-element");
        let url = element.data("autocomplete-source");
        var options = {
            url: function (phrase) {
                let bodegaId = $(element.data("bodega-id")).val();
                if (bodegaId === undefined) {
                    let alCostoCheckBox = $(element.data("alcostocheckbox")).is(":checked");
                    if (alCostoCheckBox) {
                        return url + "?term=" + phrase;
                    }
                    else {
                        return url + "?term=" + phrase + "&bodegaId=compra";
                    }
                }
                else if (bodegaId == '') {
                    return url + "?term=" + phrase + "&bodegaId=";
                }
                else {
                    return url + "?term=" + phrase + "&bodegaId=" + bodegaId;
                }
            },
            list: {
                maxNumberOfElements: 15,
                onSelectItemEvent: function () {
                    if (idElement) {
                        let id = element.getSelectedItemData().id;
                        $(idElement).val(id);
                    }
                    if (element.hasClass("autocomplete_cuenta_movimientos_detalle_movimiento_contable_fields")) {
                        actualizar_campos_exigidos_cuenta(element);
                    }
                    if (element.hasClass("autocomplete_detalle_item")) {
                        consultarPrecioUnitario(element);
                    }
                    if (element.hasClass("autocomplete_detalle_item_ajuste")) {
                        consultarPrecioUnitarioParaAjuste(element);
                    }
                    if (element.hasClass("autocomplete_documento_referencia_nota_debito")) {
                        consultarFacturaNotaDebito(element);
                    }
                },
                onHideListEvent: function() {
                    if (element.val() == "") {
                        $(idElement).val("");
                    }
                }
            },
            getValue: "label"
        };
        element.easyAutocomplete(options);
        element.focus();
    }
}

document.addEventListener("turbolinks:load", ajustarAutocomplete);