var actualizar_detalle_subtotal_compra_keyup, actualizarPrecioCompraVentaJquery, actualizarSubtotalCompraVentaJquery,
  actualizar_subtotal_impuesto_compra, actualizar_subtotal_retencion_compra, actualizar_total_compra, actualizar_total_forma_de_pago_compra,
  actualizar_total_movimiento_jquery, actualizar_total_retencion_compra,
  cambiar_visibilidad, consultar_impuesto_compra, consultar_impuesto_retencion,
  formatCurrency,
  mostrar_ocultar_detalle_cuenta_por_pagar_compra,
  mostrar_ocultar_informacion_adicional_compra,
  mostrarOcultarInteresNotaDebito,
  listaPreciosPorItem = {},
  precioUnitarioPorItem = {};

$(document).ready(function () {
  //   Actualizar totales debito y credito cuando cambian los valores en el detalle
  $(document).on("keyup", ".input_detalle_debito, .detalle_credito", actualizar_total_movimiento_jquery);
  $(document).on("nested:fieldRemoved", $("form").filter('[class$="_movimiento_contable"]'), actualizar_total_movimiento_jquery);
  //   Actualizar totales debito y credito cuando cambian los valores en el detalle transacción item
  $(document).on("keyup", ".detalle_cantidad_venta", actualizarPrecioCompraVentaJquery);
  $(document).on("keyup", ".detalle_cantidad_compra, .detalle_precio_compra", actualizarSubtotalCompraVentaJquery);
  $(document).on("keyup", ".detalle_cantidad_ajuste, .detalle_precio_ajuste", actualizarSubtotalAjusteJquery);
  $(document).on("keyup", "#movimiento_contable_datos_dian_movimiento_attributes_tasa_interes", actualizarValorNotaDebito);
  $(document).on("keyup", "#movimiento_contable_datos_dian_movimiento_attributes_valor", actualizarTasaInteresNotaDebito);
  $(document).on("keyup", ".detalle_valor_impuesto", actualizar_total_compra);
  $(document).on("keyup", ".detalle_compra_venta_valor", actualizar_detalle_subtotal_compra_keyup);
  $(document).on("keyup", ".detalle_valor_forma_de_pago", actualizar_total_forma_de_pago_compra);
  $(document).on("nested:fieldRemoved", $("form").filter('[class$="_compra"]'), actualizar_total_compra);
  $(document).on("nested:fieldRemoved", $("form").filter('[class$="_ajuste"]'), actualizarTotalAjuste);
  $(document).on("click", ".detalle_al_gasto", mostrar_ocultar_bodega_compra);
  $(document).on("change", ".seleccion_impuesto_compra_venta", consultar_impuesto_compra);
  $(document).on("change", ".seleccion_impuesto_retencion", consultar_impuesto_retencion);
  $(document).on("change", ".seleccion_impuesto_nota_debito", consultarImpuestoNotaDebito);
  $(document).on("change", ".seleccion_bodega_venta", limpiarItemVenta);
  $(document).on("change", ".seleccion_concepto_correccion_nota_debito", mostrarOcultarInteresNotaDebito);
  $(document).on("change", ".seleccion_dian_forma_de_pago_compra_venta", mostrar_ocultar_detalle_cuenta_por_pagar_compra);
  $(document).on("change", ".seleccion_dian_medio_de_pago_compra_venta", mostrar_ocultar_informacion_adicional_compra);

  if ($("#factura_venta_con_factura_electronica")) {
    var i, j, l;
    l = JSON.parse(document.getElementById("lista-precios").getAttribute('data-lista-de-precios'));
    for (i in l) {
      listaPreciosPorItem[l[i][0].item_id] = l[i];
    }
    l = JSON.parse(document.getElementById("lista-precios").getAttribute('data-items'));
    for (i in l) {
      precioUnitarioPorItem[l[i].id] = l[i].precio_unitario;
    }
  }
});

actualizar_detalle_subtotal_compra_keyup = function (jQueryEvent) {
  var padre, tasa;
  padre = $(jQueryEvent.currentTarget).parents('.temporal');
  tasa = $(padre).children().filter('[class$="detalle_compra_tasa"]').children().val();
  return actualizar_subtotal_retencion_compra(padre, tasa);
};

actualizar_subtotal_impuesto_compra = function (padre, inputTasa) {
  var valor_base, tasa;
  valor_base = $(padre).children().filter('[class$="detalle_subtotal"]').children().val();
  valor_base = valor_base === "" ? 0 : parseFloat(valor_base);
  tasa = inputTasa == "" ? 0 : parseFloat(inputTasa);
  $(padre).children().filter('[class$="detalle_valor_impuesto"]').children().val(valor_base * tasa);
  return actualizar_total_compra();
};

actualizar_subtotal_retencion_compra = function (padre, tasa) {
  var valor_base;
  valor_base = $(padre).children().filter('[class$="detalle_compra_venta_valor"]').children().val();
  valor_base = valor_base === "" ? 0 : parseFloat(valor_base);
  $(padre).children().filter('[class$="detalle_subtotal_retencion"]').children().attr('value', valor_base * tasa);
  actualizar_total_compra();
};

var actualizarSubtotalesNotaDebito = function (inputTasa) {
  var valorBase, tasa, valorImpuesto;
  // Actualizar el valor de impuesto
  valorBase = $("#movimiento_contable_datos_dian_movimiento_attributes_valor").val();
  valorBase = valorBase === "" ? 0 : parseFloat(valorBase);
  tasa = inputTasa == "" ? 0 : parseFloat(inputTasa);
  valorImpuesto = valorBase * tasa;
  $("#movimiento_contable_datos_dian_movimiento_attributes_valor_impuesto").val(valorImpuesto);
  // Actualizar el total de la nota débito
  $("#movimiento_contable_datos_dian_movimiento_attributes_total_nota_debito").val(valorBase + valorImpuesto)
};

let actualizarSubtotalAjusteJquery = function (jQueryEvent) {
  let padre = $(jQueryEvent.currentTarget).parents('.temporal');
  actualizarFilaAjuste(padre);
};

actualizarPrecioCompraVentaJquery = function (jQueryEvent) {
  let padre = $(jQueryEvent.currentTarget).parents('.temporal');
  actualizarPrecioUnitario(padre, jQueryEvent.currentTarget);
  actualizarFilaCompra(padre);
};

actualizarSubtotalCompraVentaJquery = function (jQueryEvent) {
  let padre = $(jQueryEvent.currentTarget).parents('.temporal');
  actualizarFilaCompra(padre);
};

export function actualizarFilaAjuste(padre) {
  var cantidad, precio_unitario, subtotal;
  cantidad = $(padre).children().filter('[class$="detalle_cantidad_ajuste"]').children().val();
  cantidad = cantidad === "" ? 0 : parseFloat(cantidad);
  precio_unitario = $(padre).children().filter('[class$="detalle_precio_ajuste"]').children().val();
  precio_unitario = precio_unitario === "" ? 0 : parseFloat(precio_unitario);
  subtotal = cantidad * precio_unitario;
  //$(padre).children().filter('[class$="detalle_subtotal_ajuste"]').children().attr('value', subtotal);
  $(padre).children().filter('[class$="detalle_subtotal_ajuste"]').children().val(subtotal);
  actualizarTotalAjuste();
};

export function actualizarFilaCompra(padre) {
  var cantidad, precio_unitario, subtotal, tasa_impuesto;
  //cantidad = $(padre).children().filter('[class$="detalle_cantidad_compra"]').children().val();
  cantidad = consultarInput(padre, "detalle_cantidad_compra");
  cantidad = cantidad === "" ? 0 : parseFloat(cantidad);
  //precio_unitario = $(padre).children().filter('[class$="detalle_precio_compra"]').children().val();
  precio_unitario = consultarInput(padre, "detalle_precio_compra");
  precio_unitario = precio_unitario === "" ? 0 : parseFloat(precio_unitario);
  subtotal = cantidad * precio_unitario;
  //$(padre).children().filter('[class$="detalle_subtotal"]').children().attr('value', subtotal);
  //$(padre).children().filter('[class$="detalle_subtotal"]').children().val(subtotal);
  actualizarInput(padre, "detalle_subtotal", subtotal)
  tasa_impuesto = $(padre).children().filter('[class$="detalle_tasa_impuesto_compra_venta"]').children().val();
  tasa_impuesto = (tasa_impuesto === "" || tasa_impuesto === undefined) ? 0 : parseFloat(tasa_impuesto);
  //$(padre).children().filter('[class$="detalle_valor_impuesto"]').children().val(subtotal * tasa_impuesto);
  actualizarInput(padre, "detalle_valor_impuesto", subtotal * tasa_impuesto);
  actualizar_total_compra();
};

function actualizarPrecioUnitario(padre, currentTarget) {
  var itemId, cantidad, precio;
  cantidad = $(currentTarget).val();
  itemId = consultarInput(padre, 'detalle_item');
  if (listaPreciosPorItem[itemId] !== undefined)
    precio = buscarPrecioEnLista(cantidad, listaPreciosPorItem[itemId], precioUnitarioPorItem[itemId]);
  else
    precio = precioUnitarioPorItem[itemId];
  actualizarInput(padre, 'detalle_precio_compra', precio);
};

let actualizarTotalAjuste = function () {
  var subtotal;
  subtotal = 0;
  $('.detalle_subtotal_ajuste input').filter(':visible').each(function () {
    return subtotal += this.value === "" ? 0 : parseFloat(this.value);
  });
  return $("#total_ajuste").text(formatCurrency(subtotal));
};

actualizar_total_compra = function () {
  var impuestos, subtotal;
  subtotal = impuestos = 0;
  $('.detalle_subtotal input').filter(':visible').each(function () {
    return subtotal += this.value === "" ? 0 : parseFloat(this.value);
  });
  $('.detalle_valor_impuesto input').filter(':visible').each(function () {
    return impuestos += this.value === "" ? 0 : parseFloat(this.value);
  });
  let total = subtotal + impuestos;
  let total_retencion = calcular_total_retencion_compra();
  actualizar_total_retencion_compra(total_retencion);
  actualizar_total_a_pagar(total - total_retencion);
  actualizar_total_forma_de_pago_compra();
  $("#subtotal_precio_compra").text(formatCurrency(subtotal));
  $("#subtotal_impuesto_compra").text(formatCurrency(impuestos));
  return $("#total_precio_compra").text(formatCurrency(total));
};

actualizar_total_movimiento_jquery = function () {
  var t_credito, t_debito;
  t_debito = t_credito = 0;
  $('.detalle_debito input').filter(':visible').each(function () {
    return t_debito += this.value === "" ? 0 : parseFloat(this.value);
  });
  $('.detalle_credito input').filter(':visible').each(function () {
    return t_credito += this.value === "" ? 0 : parseFloat(this.value);
  });
  $("#total_debitos").text(formatCurrency(t_debito));
  return $("#total_creditos").text(formatCurrency(t_credito));
};

actualizar_total_forma_de_pago_compra = function () {
  var subtotal;
  subtotal = 0;
  $('.detalle_valor_forma_de_pago input').filter(':visible').each(function () {
    return subtotal += this.value === "" ? 0 : parseFloat(this.value);
  });
  return $("#total_forma_de_pago_compra").text(formatCurrency(subtotal));
};

actualizar_total_retencion_compra = function (retencion) {
  return $("#total_retencion").text(formatCurrency(retencion));
};

let actualizar_total_a_pagar = function (valor) {
  return $("#total_a_pagar").text(formatCurrency(valor));
};

let calcular_total_retencion_compra = function () {
  var retencion = 0;
  $('.detalle_subtotal_retencion input').filter(':visible').each(function () {
    return retencion += this.value === "" ? 0 : parseFloat(this.value);
  });
  return retencion;
};

let actualizarTasaInteresNotaDebito = function () {
  var valor_documento_referencia, tasa, valor;
  valor_documento_referencia = $('#movimiento_contable_datos_dian_movimiento_attributes_valor_documento_referencia').val();
  if (valor_documento_referencia !== "") {
    valor_documento_referencia = parseFloat(valor_documento_referencia);
    valor = $('#movimiento_contable_datos_dian_movimiento_attributes_valor').val();
    valor = valor === "" ? 0 : parseFloat(valor);
    tasa = (valor / valor_documento_referencia) * 100;
    $('#movimiento_contable_datos_dian_movimiento_attributes_tasa_interes').val(tasa);
    actualizarSubtotalesNotaDebito($("#movimiento_contable_datos_dian_movimiento_attributes_tasa_impuesto").val());
  }
  else {
    $('#movimiento_contable_datos_dian_movimiento_attributes_tasa_interes').val('');
    $("#movimiento_contable_datos_dian_movimiento_attributes_tasa_impuesto").val('');
  }
};

let actualizarValorNotaDebito = function () {
  var valor_documento_referencia, tasa, valor;
  valor_documento_referencia = $('#movimiento_contable_datos_dian_movimiento_attributes_valor_documento_referencia').val();
  valor_documento_referencia = valor_documento_referencia === "" ? 0 : parseFloat(valor_documento_referencia);
  tasa = $('#movimiento_contable_datos_dian_movimiento_attributes_tasa_interes').val();
  tasa = tasa === "" ? 0 : parseFloat(tasa);
  valor = valor_documento_referencia * tasa / 100;
  $('#movimiento_contable_datos_dian_movimiento_attributes_valor').val(valor);
  actualizarSubtotalesNotaDebito($("#movimiento_contable_datos_dian_movimiento_attributes_tasa_impuesto").val());
};

formatCurrency = function (num) {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(num);
};


//Actualizar campos exigidos según la cuenta

export function actualizar_campos_exigidos_cuenta(autocomplete_cuenta) {
  $.get('/cuentas/' + $($(autocomplete_cuenta).attr('data-id-element')).attr('value') + '/consultar_campos_exigidos', function (data) {
    var padre;
    padre = $(autocomplete_cuenta).parents('.temporal');
    cambiar_visibilidad(padre, '.detalle_valor_base', data.exige_valor_base);
    cambiar_visibilidad(padre, '.detalle_tercero', data.exige_tercero);
    return cambiar_visibilidad(padre, '.detalle_centro_de_costo_movimiento', data.exige_centro_de_costo);
  });
};

cambiar_visibilidad = function (div_detalle, div_hijo, visibilidad) {
  var hijo;
  hijo = $(div_detalle).children(div_hijo);
  if (visibilidad) {
    return hijo.children().filter(":visible").css('visibility', 'visible');
  } else {
    hijo.children().filter(":visible").css('visibility', 'hidden');
    //a todos los input o select, incluido el hidden de un autocomplete
    return hijo.children().each(function () {
      return $(this).attr('value', '');
    });
  }
};

consultar_impuesto_compra = function (jQueryEvent) {
  var impuesto_id, padre, tasa;
  padre = $(jQueryEvent.currentTarget).parents('.temporal');
  impuesto_id = $(jQueryEvent.currentTarget).val();
  tasa = 0;
  if (impuesto_id !== "") {
    return $.get('/impuestos/' + impuesto_id, function (data) {
      tasa = data.tasa;
      $(padre).children().filter('[class$="detalle_tasa_impuesto_compra_venta"]').children().attr('value', tasa);
      return actualizar_subtotal_impuesto_compra(padre, tasa);
    }, 'json');
  } else {
    $(padre).children().filter('[class$="detalle_tasa_impuesto_compra_venta"]').children().attr('value', '');
    return actualizar_subtotal_impuesto_compra(padre, tasa);
  }
};

consultar_impuesto_retencion = function (jQueryEvent) {
  var impuesto_id, padre, tasa;
  padre = $(jQueryEvent.currentTarget).parents('.temporal');
  impuesto_id = $(jQueryEvent.currentTarget).val();
  tasa = 0;
  if (impuesto_id !== "") {
    return $.get('/impuestos/' + impuesto_id, function (data) {
      tasa = data.tasa;
      $(padre).children().filter('[class$="detalle_compra_tasa"]').children().attr('value', tasa);
      return actualizar_subtotal_retencion_compra(padre, tasa);
    }, 'json');
  } else {
    $(padre).children().filter('[class$="detalle_compra_tasa"]').children().attr('value', '');
    return $(padre).children().filter('[class$="detalle_subtotal_retencion"]').children().attr('value', 0);
  }
};

export function consultarFacturaNotaDebito(element) {
  var factura_id;
  factura_id = $('.factura_id_nota_debito').val();
  if (factura_id !== "") {
    return $.get('/venta_con_factura_electronicas/' + factura_id + '/ver_total', function (data) {
      $('#movimiento_contable_tercero_id').attr('value', data.tercero_id);
      $('#movimiento_contable_tercero_nombre').attr('value', data.tercero_nombre);
      $('#movimiento_contable_datos_dian_movimiento_attributes_valor_documento_referencia').
        attr('value', data.total);
      actualizarValorNotaDebito();
    }, 'json');
  } else {
    $('#movimiento_contable_tercero_id').attr('value', '');
    $('#movimiento_contable_tercero_nombre').attr('value', '');
    $('#movimiento_contable_datos_dian_movimiento_attributes_valor_documento_referencia').attr('value', '');
  }
};

function consultarImpuestoNotaDebito(jQueryEvent) {
  var impuesto_id, tasa;
  impuesto_id = $(jQueryEvent.currentTarget).val();
  tasa = 0;
  if (impuesto_id !== "") {
    return $.get('/impuestos/' + impuesto_id, function (data) {
      tasa = data.tasa;
      $("#movimiento_contable_datos_dian_movimiento_attributes_tasa_impuesto").attr('value', tasa);
      return actualizarSubtotalesNotaDebito(tasa);
    }, 'json');
  } else {
    $("#movimiento_contable_datos_dian_movimiento_attributes_tasa_impuesto").attr('value', '');
    return actualizarSubtotalesNotaDebito(tasa);
  }
};

function actualizarInput(padre, claseCss, nuevoValor) {
  if ($(padre).children().filter('[class$="' + claseCss + '"]').children()[0].className == "field_with_errors") {
    $(padre).children().filter('[class$="' + claseCss + '"]').children().children().val(nuevoValor);
  }
  else {
    $(padre).children().filter('[class$="' + claseCss + '"]').children().val(nuevoValor);
  }
}

function consultarInput(padre, claseCss) {
  if ($(padre).children().filter('[class$="' + claseCss + '"]').children()[0].className == "field_with_errors") {
    return $(padre).children().filter('[class$="' + claseCss + '"]').children().children().first().val();
  }
  else {
    return $(padre).children().filter('[class$="' + claseCss + '"]').children().first().val();
  }
}

export function buscarPrecioEnLista(cantidad, listaDePrecios, precioUnitario) {
  var contador = 0, p = 'Precio por definir';
  let lp = listaDePrecios.find((precio) => {
    let ci = parseFloat(precio['cantidad_inicial']);
    let cf = parseFloat(precio['cantidad_final']);
    let c = parseFloat(cantidad);
    // p = precio['precio'];
    contador++;
    return (ci <= c && c <= cf);
  });
  if (lp !== undefined) {
    return lp.precio;
  }
  else {
    //if (contador == 0 || cantidad == "" || parseFloat(cantidad) == 0)
    if (contador == 0)
      return precioUnitario;
    else
      return p;
  }
}

export function consultarPrecioUnitario(element) {
  var itemId, padre, cantidad, precio;
  padre = element.parents('.temporal');
  itemId = consultarInput(padre, 'detalle_item');
  if (itemId !== "") {
    $.get('/items/' + itemId, function (data) {
      listaPreciosPorItem[itemId] = data.listaDePrecios;
      precioUnitarioPorItem[itemId] = data.precioUnitario;
      cantidad = consultarInput(padre, 'detalle_cantidad_compra');
      precio = buscarPrecioEnLista(cantidad, data.listaDePrecios, data.precioUnitario);
      actualizarInput(padre, 'detalle_precio_compra', precio);
      actualizarFilaCompra(element.parents('.temporal'));
    }, 'json');
  } else {
    actualizarInput(padre, 'detalle_precio_compra', '');
    actualizarFilaCompra(element.parents('.temporal'));
  }
};

export function consultarPrecioUnitarioParaAjuste(element) {
  var itemId, padre;
  padre = element.parents('.temporal');
  itemId = consultarInput(padre, 'detalle_item_ajuste');
  if (itemId !== "") {
    $.get('/items/' + itemId, function (data) {
      actualizarInput(padre, 'detalle_precio_ajuste', data.precioUnitario);
      actualizarFilaAjuste(element.parents('.temporal'));
    }, 'json');
  } else {
    actualizarInput(padre, 'detalle_precio_ajuste', '');
    actualizarFilaAjuste(element.parents('.temporal'));
  }
};

let limpiarItemVenta = function (jQueryEvent) {
  var padre;
  padre = $(jQueryEvent.currentTarget).parents('.temporal');
  $(padre).children().filter('[class$="detalle_item"]').children().val('');
  $(padre).children().filter('[class$="detalle_item"]').children('.easy-autocomplete').children().val('')
  $(padre).children().filter('[class$="detalle_cantidad_compra"]').children().val('');
  $(padre).children().filter('[class$="detalle_precio_compra"]').children().val('');
  $(padre).children().filter('[class$="detalle_subtotal"]').children().val('');
  $(padre).children().filter('[class$="detalle_valor_impuesto"]').children().val('');
}

let mostrar_ocultar_bodega_compra = function (jQueryEvent) {
  var padre, temporal;
  padre = $(jQueryEvent.currentTarget).parents('.temporal');
  temporal = $(padre).children().filter('[class$="detalle_bodega"]').css('display');
  if (temporal == "block") {
    $(padre).children().filter('[class$="detalle_bodega"]').children().val('');
  }
  else {
    $(padre).children().filter('[class$="detalle_centro_de_costo"]').children().val('');
  }
  $(padre).children().filter('[class$="detalle_item"]').children().val('');
  $(padre).children().filter('[class$="detalle_item"]').children('.easy-autocomplete').children().val('')
  $(padre).children().filter('[class$="detalle_bodega"]').css('display', $(padre).children().filter('[class$="detalle_centro_de_costo"]').css('display'));
  $(padre).children().filter('[class$="detalle_centro_de_costo"]').css('display', temporal);
};

mostrar_ocultar_detalle_cuenta_por_pagar_compra = function (jQueryEvent) {
  var divTemporal, opcion, padre, selectedIndex;
  divTemporal = $(jQueryEvent.currentTarget).parents('.temporal');
  padre = $(divTemporal).children().filter('[class$="detalle_datos_especificos"]');
  selectedIndex = jQueryEvent.currentTarget.selectedIndex;
  opcion = codigos_dian_forma_de_pago[jQueryEvent.currentTarget.options[selectedIndex].text];
  if (opcion == '2') {
    return $(padre).children().filter('[class$="detalle_cuenta_por_pagar"]').css('display', 'block');
  } else {
    return $(padre).children().filter('[class$="detalle_cuenta_por_pagar"]').css('display', 'none');
  }
};

mostrar_ocultar_informacion_adicional_compra = function (jQueryEvent) {
  var divTemporal, opcion, padre, selectedIndex;
  divTemporal = $(jQueryEvent.currentTarget).parents('.temporal');
  padre = $(divTemporal).children().filter('[class$="detalle_datos_especificos"]');
  selectedIndex = jQueryEvent.currentTarget.selectedIndex;
  opcion = codigos_sistema_forma_de_pago[jQueryEvent.currentTarget.options[selectedIndex].text];
  switch (opcion) {
    case '10':
      $(padre).children().filter('[class$="detalle_consignacion"]').css('display', 'none');
      return $(padre).children().filter('[class$="detalle_cheque"]').css('display', 'none');
    case '20':
    case '23':
    case '25':
    case '26':
    case '92':
      $(padre).children().filter('[class$="detalle_consignacion"]').css('display', 'block');
      return $(padre).children().filter('[class$="detalle_cheque"]').css('display', 'block');
    case '30':
    case '42':
    case '45':
    case '46':
    case '47':
      $(padre).children().filter('[class$="detalle_consignacion"]').css('display', 'block');
      return $(padre).children().filter('[class$="detalle_cheque"]').css('display', 'none');
    case '48':
      $(padre).children().filter('[class$="detalle_consignacion"]').css('display', 'block');
      return $(padre).children().filter('[class$="detalle_cheque"]').css('display', 'none');
    case '49':
      $(padre).children().filter('[class$="detalle_consignacion"]').css('display', 'none');
      return $(padre).children().filter('[class$="detalle_cheque"]').css('display', 'none');
    default:
      $(padre).children().filter('[class$="detalle_consignacion"]').css('display', 'none');
      return $(padre).children().filter('[class$="detalle_cheque"]').css('display', 'none');
  }
};

mostrarOcultarInteresNotaDebito = function (jQueryEvent) {
  var opcion, selectedIndex;
  selectedIndex = jQueryEvent.currentTarget.selectedIndex;
  opcion = jQueryEvent.currentTarget.options[selectedIndex].text;
  if (opcion == 'Intereses') {
    return $('.interes_nota_debito').css('display', 'block');
  } else {
    return $('.interes_nota_debito').css('display', 'none');
  }
};


